import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import SEO from '../components/seo';
import PhxHourlyTemps from '../components/chart-phoenix-hourly-temps';
import { graphql } from 'gatsby';
import Img from "gatsby-image";
import ClimateChangedOpenGraphImage from '../images/social-climate-changed.jpg';
export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "phoenix.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="The Climate We Grew Up With Isn't Coming Back" description="We can't return to the climate we knew, but we can prevent an unbearable future" image={'https://storybook.earth' + ClimateChangedOpenGraphImage} pageCanonical={'https://storybook.earth/climate-changed'} mdxType="SEO" />
    <Img className="story-header-gatsby-img" fluid={props.data.imageOne.childImageSharp.fluid} mdxType="Img" />
    <div className="story-content">
      <p className="txt-low-emph txt-center">Phoenix, Arizona: America's hottest city will feel more like present day Baghdad in 30 years.</p>
      <div className="txt-center mvxxl">
    <h1 className="phm">The climate we grew up with isn't coming back</h1>
    <div className="mtm">
        <p className="mbn title-font">By Mike McDearmon</p>
        <p className="mtn txt-low-emph txt-center">Sep 01, 2020 | 4 min read</p>
    </div>
      </div>
      <p>{`In Phoenix, Arizona, summer evenings don't cool off like they used to. The hot season lasts longer. The air is drier. We experience record breaking weather year after year, wondering if next year will be back to the normal we grew up with. It won't.`}</p>
      <p>{`My favorite time of year growing up in Phoenix during the 90s was the summer monsoon season. In a city where the forecast is reliably hot and sunny, monsoon storms are the most exciting weather events of the year. There's a lot to love about a monsoon - watching thunderheads brew on the horizon, the wall of dust that arrives just before the storm, the brief deluge that turns suburban streets into dusty brown rivers, and the damp air left behind that carries the scent of desert creosote.`}</p>
      <p>{`As years passed the monsoon season became a less reliable summertime presence. It arrived later, and once it did, that familiar wall of thunderheads on the horizon was more likely to dissipate without fanfare than bring any rain to the city. TV news would explain a hot or dry spell as an anomaly, seldom if ever drawing connections between what we were experiencing and the changing climate. `}</p>
      <p>{`But records of the past reveal an underlying transformation: what's normal now is not what used to be. In Phoenix, summertime in the 1950s was made more tolerable by cool nights that dropped down to just below 80 degrees on average. By the 2010s, nighttime lows no longer offered the same relief. The average low temperature had risen 7 degrees.`}</p>
      <div className="mtxxl txt-center">
        <h3>Avg July Temperatures in Phoenix, 1950s vs the 2010s</h3>
        <h4 className="title-font mts mbn"></h4>
        <PhxHourlyTemps mdxType="PhxHourlyTemps" />
        <div className="davi-chart-bottom">
    <div className="x-axis-label mtm">Month of July</div>
    <div className="legend mtm">
        <div className="legend_label mrm">
            <div className="label_line line-primary"></div>
            <div>1950-1959 Avg Temp (°F)</div>
        </div>
        <div className="legend_label mrm">
            <div className="label_line line-secondary"></div>
            <div>2010-2019 Avg Temp (°F)</div>
        </div>
    </div>
        </div>
        <p className="phxl mbxxl caption">Data for this chart comes from the National Oceanic and Atmospheric Administration and was recorded at the Phoenix Sky Harbor Airport weather station <span className="sup">[1]</span>.</p>
      </div>
      <p>{`This bottom-up compression of daily temperature ranges between the 1950s and 2010s in Phoenix is indicative of global warming and urban heat island effect working in tandem. More heat from daytime sunshine is trapped in the atmosphere by greenhouse gasses, preventing evenings from cooling off like they used to. At the same time, buildings and other city infrastructure generate heat at all hours of the day where previously undeveloped desert would have enabled temperatures to fall at night. While Phoenix is a standout in terms of its recent population growth and expansion, all cities in the US are heating up faster than the global average `}<span className="sup">{`[2]`}</span>{`.`}</p>
      <p>{`As for the disappearing monsoon season, there is evidence linking it, too, with rising temperatures. While warmer air can hold more moisture and increase the chance of precipitation in some places, in others like the desert southwest it can evaporate so much water from the soil and carry it away that it increases the risk of drought instead. A research team lead by Columbia University and NASA GISS climate scientist Kate Marvel studied this phenomenon in 2019, concluding that greenhouse gas emissions have probably impacted the worldwide risk of drought from as early as the beginning of the twentieth century `}<span className="sup">{`[3]`}</span>{`.`}</p>
      <p>{`Looking ahead we face a future we know will be warmer, less predictable, and harder. By 2050, living in Phoenix will feel more like present day Baghdad, which set an all time high temperature record this summer of 125 degrees `}<span className="sup">{`[4]`}</span>{`. Lessons from Baghdad's present can help Phoenix prepare for a future which will include lengthier heatwaves and more severe droughts `}<span className="sup">{`[5]`}</span>{`.`}</p>
      <div className="mtxxl txt-center">
        <h3>Precipitation and Temperature Changes for Major US Cities in 2050</h3>
        <h4 className="title-font mts mbl">Mouse over or tap on a city for details</h4>
        <div className="tableau-container">
    <div className="tableau-hide-toolbar"></div>
    <div className="tableau-hide-leftframe"></div>
    <div className="tableau-hide-rightframe"></div>
    <div className="tableau-hide-topframe"></div>
    <iframe frameBorder="false" height="100%" width="100%" scrolling="no" src="https://public.tableau.com/views/Future-City-Analogs/2050CitiesClimateChange?:embed=yes&:showVizHome=no&:toolbar=no">
    </iframe>
        </div>
        <div className="davi-chart-bottom">
    <div className="legend mtn">
        <div className="">Less Warming</div>
        <div className="mls legend_circle circle-1"></div>
        <div className="legend_circle circle-2"></div>
        <div className="legend_circle circle-3"></div>
        <div className="legend_circle circle-4"></div>
        <div className="legend_circle circle-5"></div>
        <div className="legend_circle circle-6"></div>
        <div className="legend_circle circle-7"></div>
        <div className="mls">More Warming</div>
    </div>
        </div>
        <p className="phxl mbxxl caption">These projections for warming and precipitation in major US cities come from a 2019 analysis by Crowther Lab <span className="sup">[6]</span> and are based on an intermediate global greenhouse gas emissions scenario <span className="sup">[7]</span>.</p>
      </div>
      <p>{`A future in which Phoenix feels like Baghdad may not sound aspirational, but that outcome assumes significant action is taken around the world to curb the emission of greenhouse gasses over the coming decades. It's part of an intermediate scenario for global emissions in the near future, making it possible that 30 years from now Phoenix could be even warmer.`}</p>
      <p>{`Yet the future remains in our control. We can't return to the climate we knew, but we can prevent an unbearable future. Kate Marvel puts it this way: "We have a choice. We can choose to be completely screwed if we want to. But we also have the ability to limit the damage. We cannot prevent climate change. It's already here. It's already hurting people. But our choices, especially in this next decade, really really matter" `}<span className="sup">{`[8]`}</span>{`.`}</p>
      <div className="references mvxxl">
    <strong>References</strong>
    <ol>
        <li><a href="https://www.ncdc.noaa.gov/cdo-web/datatools/lcd" target="blank">Data Tools: Local Climatological Data (LCD)</a>, National Centers for Environmental Information, NOAA, Aug 2020. Accessed: Mar 12, 2020</li>
        <li>Brian Stone, Jr, <a href="https://www.cambridge.org/core/books/city-and-the-coming-climate/6BC2C28AF3A6D1E9FA1974F5E66149DF" target="blank">The City and the Coming Climate</a>, Cambridge University Press, 2012</li>
        <li>Kate Marvel, Benjamin I. Cook, Céline J. W. Bonfils, Paul J. Durack, Jason E. Smerdon & A. Park Williams, <a href="https://www.nature.com/articles/s41586-019-1149-8" target="blank">Twentieth-century hydroclimate changes consistent with human influence</a>, Nature, 2019</li>
        <li>Falih Hassan, Elian Peltier, <a href="https://www.nytimes.com/2020/07/31/world/middleeast/Middle-East-heat-wave.html" target="blank">Scorching Temperatures Bake Middle East Amid Eid al-Adha Celebrations</a>, The New York Times, 2020</li>
        <li>Sarah Kaplan, <a href="https://www.washingtonpost.com/graphics/2020/climate-solutions/phoenix-climate-change-heat/" target="blank">How America’s hottest city will survive climate change</a>, The Washington Post, 2020</li>
        <li>Jean-Francois Bastin, Emily Clark, Thomas Elliott, Simon Hart, Johan van den Hoogen, Iris Hordijk, Haozhi Ma, Sabiha Majumder, Gabriele Manoli, Julia Maschler, Lidong Mo, Devin Routh, Kailiang Yu, Constantin M. Zohner, Thomas W. Crowther <a href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0217592#sec002" target="blank">Understanding Climate Change from a Global Analysis of City Analogues</a>, Plos One, 2019</li>
        <li><a href="https://sos.noaa.gov/datasets/climate-model-temperature-change-rcp-45-2006-2100/" target="blank">Climate Model: Temperature Change (RCP 4.5) - 2006 - 2100</a>, Science On a Sphere - National Oceanic and Atmospheric Administration, 2020, Accessed: Aug 23, 2020</li>
        <li>Ayana Elizabeth Johnson, Alex Blumberg, <a href="https://open.spotify.com/episode/4A0ZcNPdYDxmaBli2P54FJ?si=u93c4u7zT_aipQMPX4TkqA" target="blank">How Screwed Are We?</a>, How to Save a Planet, 2020</li>
    </ol>
      </div>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      